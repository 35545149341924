import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import YoutubeVideo from "../Components/YoutubeVideo"
import HomeFrame from "../Components/HomeFrame";

import "../CSS/Layout.css";
import "../CSS/Landing.css";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";



export default function Landing() {
  let redirectPath = useNavigate();

  return (
    <>
      <HomeFrame currentPageName="Landing Page" />
      <Box className="landing-cover">
        <Box className="cover-text">
          <Typography className="cover-title-text">IIJA Launchpad</Typography>
          <Typography mt="20px" className="cover-text-font">
            The{" "}
            <strong>
              <u>Infrastructure Investment and Jobs Act (IIJA)</u>
            </strong>{" "}
            offers unprecedented opportunities for local governments and
            communities to secure competitive federal grants and modernize
            America's infrastructure. To help navigate{" "}
            <strong>
              <u>transportation grant programs</u>
            </strong>{" "}
            and expedite applications, the IIJA Launchpad provides customized
            information on available funding, interactive technical support,
            data on successful awards, and essential resources. With this
            platform, localities can accelerate their grant application process
            and access the necessary tools to enhance their transportation
            infrastructure.
          </Typography>
        </Box>
      </Box>
      <Box className="content-body flex-column">
        <Typography color="primary" className="content-title">
          IIJA Launchpad Helps You
        </Typography>
        <Box className="navi-picture-row">
          <Box
            className="flex-column picture-width"
            onClick={() => {
              redirectPath("/nofo");
            }}
          >
            <Box
              className="navi-picture"
              component="img"
              src="/landing/FundingOpportunities.png"
            />
            <Typography className="navi-text">
              Navigate Funding Opportunities
            </Typography>
          </Box>
          <Box
            className="flex-column picture-width"
            onClick={() => {
              redirectPath("/planahead");
            }}
          >
            <Box
              className="navi-picture"
              component="img"
              src="/landing/PlanAhead.png"
            />
            <Typography className="navi-text">
              Plan Ahead for Your Grant Application
            </Typography>
          </Box>
          <Box
            className="flex-column picture-width"
            onClick={() => {
              redirectPath("/successfulawards");
            }}
          >
            <Box
              className="navi-picture"
              component="img"
              src="/landing/MatchYourProject.jpg"
            />
            <Typography className="navi-text">
              Match Your Project to Successful Awards
            </Typography>
          </Box>
          <Box
            className="flex-column picture-width"
            onClick={() => {
              redirectPath("/statedashboard");
            }}
          >
            <Box
              className="navi-picture"
              component="img"
              src="/landing/StateDashboard.jpg"
            />
            <Typography className="navi-text">
              Analyse the Discretionary Grant Awards
            </Typography>
          </Box>
          <Box
            className="flex-column picture-width"
            onClick={() => {
              redirectPath("/gethelp");
            }}
          >
            <Box
              className="navi-picture"
              component="img"
              src="/landing/GetHelp.png"
            />
            <Typography className="navi-text">
              Get Help from the Build America Center
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: "gray", width: "100%" }} />
          {/*<Box className="flex-row-center">*/}
          {/*<Box className="content-body flex-column">*/}
          <Box
              className="flex-column  ">
          <Typography className="navi-text">
                 BAC 8 tips for a successful IIJA Grant Application
          </Typography>

            <YoutubeVideo />

          </Box>
          {/*</Box>*/}
        </Box>
        <Divider sx={{ backgroundColor: "gray", width: "100%" }} />
        <Typography mt="40px" color="gray">
          The DOT Navigator is a comprehensive resource for local public
          agencies to understand IIJA grant application, plan and deliver
          infrastructure project services, as well as technical assistance
          resources.
        </Typography>
        <Box className="flex-row-center">
        <Box className="flex-row-left" sx={{ width: "100%" }}>
          <Typography color="gray" mr="10px">
            Visit DOT Navigator for IIJA Opportunities
          </Typography>
          <Link
            href="https://www.transportation.gov/dot-navigator"
            underline="hover"
            color="secondary"
            target="_blank"
          >
            DOT Navigator | US Department of Transportation
          </Link>
        </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
